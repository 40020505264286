import React from 'react';
import styled from 'styled-components';
import { getRandomNumber } from '../../../utils/maths';
import Constellation from './Constellation';

const Wrapper = styled.div`
    .leftConstel,
    .rightConstel {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 5;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        canvas {
            width: 50px;
            display: block;
            margin: 0 auto;
            &:nth-child(2n) {
                margin: 10% 80%;
            }
            &:nth-child(3n + 3) {
                margin: 5% 30%;
            }
            &:nth-child(3n + 2) {
                margin: 0 70%;
            }
            &:nth-child(4n) {
                margin: -10% 60%;
            }
        }
    }
    .leftConstel {
        grid-column: 1/3;
    }
    .rightConstel {
        grid-column: 13/16;
        transform: scaleX(-1);
    }
`;

const Constellations = () => {
    function createConstellations() {
        const count = getRandomNumber(
            Math.floor(document.body.offsetHeight / 500),
            Math.floor(document.body.offsetHeight / 400),
        );
        return [...Array(count)].map((_, i) => <Constellation key={i} />);
    }

    return (
        <Wrapper>
            <div className="leftConstel">{createConstellations()}</div>
            <div className="rightConstel">{createConstellations()}</div>
        </Wrapper>
        // <canvas className={className} ref={canvasEl} />
    );
};

export default Constellations;
