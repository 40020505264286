import React, { useEffect, useRef } from 'react';
import { getRandomNumber } from "../../../utils/maths";

const POINT_RADIUS = 2;
const CONSTEL_RADIUS = 25;

class Particle {
    constructor() {
        this.x = getRandomNumber(20, 30);
        this.y = getRandomNumber(20, 30);
        this.velX = (Math.random() - 0.5);
        this.velY = (Math.random() - 0.5);
        this.radius = POINT_RADIUS;
    }
    render(ctx) {
        ctx.beginPath();
        ctx.arc(this.x, this.y, this.radius, 0, 2 * Math.PI);
        ctx.fill();

        this.x += this.velX;
        this.y += this.velY;

        let dist = Math.sqrt(Math.pow(this.x - (CONSTEL_RADIUS), 2) + Math.pow(this.y - (CONSTEL_RADIUS), 2))
        if (dist + Math.sqrt(Math.pow(this.velX, 2) + Math.pow(this.velY, 2)) >= (CONSTEL_RADIUS) - this.radius) {
            this.velX = -this.velX;
            this.velY = -this.velY;
        }
    }
}

const Constellation = ({ className }) => {
    const canvasEl = useRef(null);

    useEffect(() => {
        let req;
        if (canvasEl.current) {
            const canvas = canvasEl.current;
            const ctx = canvas.getContext('2d');
            const width = CONSTEL_RADIUS * 2;
            const height = CONSTEL_RADIUS * 2;
            canvas.width = width;
            canvas.height = height;

            ctx.strokeStyle = "rgba(255,255,255,0.3)";
            ctx.fillStyle = "rgba(255,255,255,0.6)";
            ctx.shadowBlur = 8;
            ctx.shadowColor = "white";
            const particles = [...Array(getRandomNumber(2, 3))].map(() => new Particle());
            const step = () => {
                ctx.clearRect(0, 0, width, height);
                for (let i = 0; i < particles.length; i++) {
                    particles[i].render(ctx);
                    if (i !== particles.length - 1) {
                        ctx.beginPath();
                        ctx.moveTo(particles[i].x, particles[i].y);
                        ctx.lineTo(particles[i + 1].x, particles[i + 1].y);
                        ctx.stroke();
                    }
                }
                req = requestAnimationFrame(step);
            }
            req = requestAnimationFrame(step)
        }
        return () => {
            if (req) {
                cancelAnimationFrame(req);
            }
        };
    }, []);

    return (
        <canvas className={className} ref={canvasEl} />
    );
};

export default Constellation;
