import { Link } from 'gatsby';
import React, { useRef } from 'react';
import styled from 'styled-components';
import useViewport from "../../utils/useViewport";

const Wrapper = styled.li`
    color: white;
    .menuLink {
        display: inline-block;
        font-size: 1.4rem;
        height: 50px;
        padding-top: 18px;
        margin-left: 30px;
        font-family: 'Heebo';
        text-transform: uppercase;
        color: white;
        letter-spacing: 0.4rem;
        text-decoration: none;
    }
    .menuBars {
        width: 83vw;
        background: rgb(255, 255, 255, 0.3);
        margin: 15px 0;
        border: 0;
        height: 1px;
    }
    .expandMenu {
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 7px solid white;
        margin-left: 5px;
        display: inline-block;
    }
    .anchorsContainer {
        position: relative;
        transition: height 500ms ease-in-out, opacity 200ms ease-in-out;
        height: 0;
        opacity: 0;
        visibility: hidden;
    }
    .anchorsContainer.opened {
        height: auto;
        opacity: 1;
        visibility: visible;
    }
    .anchorsContainer.opened > .anchor {
        margin-top: 17px;
    }
    .anchor {
        transition: all 300ms ease-in-out;
        margin-left: 30px;
        letter-spacing: 0.3rem;
        margin-top: 0px;
        font-family: 'Heebo';
        position: relative;
    }
    .anchor:before {
        content: '';
        width: 2px;
        height: 14px;
        position: absolute;
        background: white;
        top: 10%;
    }
    .anchorText {
        margin-left: 30px;
        color: white;
        text-decoration: none;
        font-weight: 300;
        display: block;
        line-height: normal;
    }
    .dot {
        display: none;
    }
    @media screen and (min-width: 800px) {
        .menuLink {
            height: unset;
            padding-top: 0;
            width: unset;
            font-size: 1.2rem;
            margin-left: 0;
            margin-right: -0.4rem;
            display: block;
            text-align: center;
            line-height: 1.4;
            padding: 0 25px;
        }
        .anchorsContainer {
            margin-left: 26px;
            margin-top: 0px;
        }
        &:hover > .anchorsContainer {
            height: auto;
            position: relative;
            margin-top: 20px;
            opacity: 1;
            visibility: visible;
        }
        &:hover > .anchorsContainer > .anchor {
            margin-top: 17px;
        }
        .anchor {
            margin-left: 0;
            letter-spacing: 0.3rem;
            margin-top: 0px;
            opacity: 0.4;
            transition: all 300ms ease-in-out;
        }
        .anchor:hover {
            opacity: 1;
        }
        .anchorText {
            margin-left: 20px;
            font-size: 1.2rem;
        }
        .menuBars {
            display: none;
        }
    }
`;

const MenuItem = ({ closeMenu, link, className }) => {
    const { width } = useViewport();
    const breakpoint = 800;
    const anchorsEl = useRef();
    const expandMenuItem = () => {
        if (width <= breakpoint) {
            anchorsEl.current.classList.toggle('opened');
        }
    };
    const label = () => {
        if (link.anchors && width < breakpoint) {
            return (
                <h3 className="menuLink">
                    {link.label}
                </h3>
            )
        }
        else {
            return (
                <Link onClick={closeMenu} className="menuLink" to={link.path}>
                    {link.label}
                </Link>
            )
        }
    };
    return (
        <Wrapper className={className} onClick={link.anchors ? expandMenuItem : null}>
            {
                label()
            }
            {link.anchors ? (
                <React.Fragment>
                    <div className="expandMenu"></div>
                    <ul className="anchorsContainer" ref={anchorsEl}>
                        {link.anchors.map((anchor) => (
                            <li className="anchor" key={anchor.title}>
                                <Link
                                    onClick={closeMenu}
                                    className="anchorText"
                                    to={link.path.replace(/.$/, "") + '#' + anchor.tag}
                                    dangerouslySetInnerHTML={{ __html: anchor.title }}
                                >

                                </Link>
                            </li>
                        ))}
                    </ul>
                </React.Fragment>
            ) : null}
            <hr className="menuBars"></hr>
        </Wrapper>
    );
};

export default MenuItem;
