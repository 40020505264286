import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useRef } from 'react';
import Div100vh from 'react-div-100vh';
import styled from 'styled-components';
import colors from '../../styles/colors.js';
import { absolutify } from '../../utils/absolutify.js';
import useViewport from '../../utils/useViewport';
import MenuItem from './MenuItem';

const HeaderWrapper = styled.header.attrs((props) => ({
    className: props.className,
}))`
    font-size: 1.4rem;
    z-index: 99;
    .logo > img {
        position: absolute;
        z-index: 3;
        width: 36%;
        top: 36px;
        left: 50%;
        transform: translateX(-50%);
        transition: all 500ms ease-in-out;
    }
    .openMenu {
        position: fixed;
        color: white;
        top: 31px;
        right: 30px;
        font-size: 2.8rem;
        transform: rotate(90deg);
        text-shadow: 0px 0px 3px white;
        z-index: 99;
        transition: all 500ms ease-in-out;
        background: none;
        border: none;
    }
    nav {
        transition: all 500ms ease-in-out;
        position: fixed;
        width: 83vw;
        top: 0;
        right: -83vw;
        background: ${colors.dark_blue};
        z-index: 1;
        overflow-y: scroll;
        overflow-x: hidden;
        .menuHeader {
            display: flex;
            padding: 0 55px;
            padding-top: 37px;
            justify-content: center;
            .logoNav > img {
                display: block;
                margin: 0 auto;
                width: 36vw;
            }
            .closingButton {
                position: relative;
                padding: 25px;
                margin-left: 15%;
                background: none;
                border: none;
            }
            .closingButton:before,
            .closingButton:after {
                position: absolute;
                content: ' ';
                left: 40px;
                top: 0;
                height: 5vw;
                width: 1px;
                background-color: white;
            }
            .closingButton:before {
                transform: rotate(45deg);
            }
            .closingButton:after {
                transform: rotate(-45deg);
            }
        }
        .menuLinks {
            margin-top: 50px;
            .dot {
                display: none;
            }
        }
        .languages {
            display: flex;
            width: 100%;
            font-family: 'Heebo';
            font-size: 1.4rem;
            letter-spacing: 0.4rem;
            justify-content: center;
            margin-top: 40px;

            &.EN {
                .en {
                    font-weight: bold;
                    pointer-events: none;
                }
            }
            &.FR {
                .fr {
                    font-weight: bold;
                    pointer-events: none;
                }
            }
            .language:first-child {
                margin-right: 40px;
                position: relative;
                &:after {
                    content: '';
                    position: absolute;
                    height: 100%;
                    width: 1px;
                    background: white;
                    right: -19px;
                }
            }
        }
    }
    @media screen and (min-width: 800px) {
        width: 100%;
        padding-bottom: 30px;
        /* transition: background-size 600ms ease-out, transform 600ms ease-in-out; */
        position: absolute;
        background: linear-gradient(
                0deg,
                ${colors.dark_blue},
                ${colors.dark_blue}
            )
            no-repeat 50% -400%;
        background-size: 100% 80%;
        transition: background-size 500ms ease-out;

        &.inScroll {
            position: fixed;
            transform: translateY(-100%);
            background: rgba(${colors.dark_blue});
            background-size: 100% 100%;
        }

        &.scrollTransition {
            transition: all 500ms ease-out;
        }

        &.scrollUp {
            transform: translateY(0px);
        }
        .logo > img {
            position: unset;
            width: 15%;
            max-width: 350px;
            padding-top: 40px;
            display: block;
            margin: 0 auto;
            transform: none !important;
        }
        &:hover {
            background-size: 100% 100%;
        }
        .openMenu {
            display: none;
        }
        nav {
            overflow-y: unset;
            overflow-x: unset;
            transition: all 300ms ease-in-out;
            position: unset;
            display: block;
            width: 100%;
            margin-top: 30px;
            height: unset;
            right: 0;
            background: transparent;
            .menuHeader {
                display: none;
            }
            .menuBars {
                display: none;
            }
            .expandMenu {
                display: none;
            }
            .menuLinks {
                position: unset;
                display: flex;
                top: 0;
                bottom: unset;
                justify-content: center;
                margin: 0 auto;
                .menuLink {
                    flex-shrink: 0;
                }
                .dot {
                    width: 4px;
                    height: 4px;
                    border-radius: 100%;
                    background: white;
                    display: unset;
                    margin-top: 0.6rem;
                }
                .languages {
                    display: none;
                }
            }
        }
    }
`;

const Menu = ({ lang, menuLinks, openMenuLayout, closeMenuLayout, logo }) => {
    const { width } = useViewport();
    const breakpoint = 800;

    const self = useRef(null);
    const prevOffset = useRef(0);

    useEffect(() => {
        function handleScroll() {
            let scrollY = window.scrollY;
            if (self.current) {
                if (window.scrollY > self.current.offsetHeight) {
                    self.current.classList.add('inScroll');
                    if (scrollY > prevOffset.current) {
                        self.current.classList.remove('inScroll');
                    } else if (scrollY < prevOffset.current - 15) {
                        self.current.classList.add('scrollUp');
                        self.current.classList.add('scrollTransition');
                    }
                    prevOffset.current = scrollY;
                } else if (scrollY === 0) {
                    self.current.classList.remove('inScroll');
                    self.current.classList.remove('scrollUp');
                    self.current.classList.remove('scrollTransition');
                } else if (
                    scrollY <= self.current.offsetHeight &&
                    !self.current.classList.contains('inScroll')
                ) {
                    self.current.classList.remove('inScroll');
                    self.current.classList.remove('scrollUp');
                    self.current.classList.remove('scrollTransition');
                }
            }
        }

        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const navEl = useRef(null);
    const linkEl = useRef(null);
    const openMenuEl = useRef(null);
    const openMenu = () => {
        openMenuLayout();
        navEl.current.style.right = '0';
        linkEl.current.style.transform = 'translateX( calc( -50% - 83vw ) )';
        openMenuEl.current.style.transform = 'translateX(-83vw) rotate(90deg)';
    };

    const closeMenu = () => {
        closeMenuLayout();
        navEl.current.style.right = '-83vw';
        linkEl.current.style.transform = 'translateX(-50%)';
        openMenuEl.current.style.transform = 'translateX(0) rotate(90deg)';
    };

    const buildMenu = (lang) => {
        return (
            <>
                <div className="menuHeader">
                    <Link
                        className="logoNav"
                        onClick={closeMenu}
                        to={lang === 'FR' ? '/fr' : '/'}
                    >
                        <img src={absolutify(logo.url)} alt={logo.title} />
                    </Link>
                    <button
                        className="closingButton"
                        onClick={closeMenu}
                        aria-label="close menu"
                    />
                </div>

                <ul className="menuLinks">
                    {menuLinks.map((link, i) => (
                        <Fragment key={link.label}>
                            <MenuItem
                                className="menuLink"
                                closeMenu={closeMenu}
                                link={link}
                            />
                            {i + 1 !== menuLinks.length && (
                                <li className="dot"></li>
                            )}
                        </Fragment>
                    ))}

                    <li className={lang + ' languages'}>
                        <Link to="/" className="language en">
                            EN
                        </Link>

                        <Link to="/fr" className="language fr">
                            FR
                        </Link>
                    </li>
                </ul>
            </>
        );
    };

    return (
        <HeaderWrapper ref={self}>
            <Link className="logo" to={lang === 'FR' ? '/fr' : '/'}>
                <img ref={linkEl} src={absolutify(logo.url)} alt={logo.title} />
            </Link>
            <button
                ref={openMenuEl}
                onClick={openMenu}
                className="openMenu"
                aria-label="open menu"
            >
                ...
            </button>
            <nav ref={navEl}>
                {width < breakpoint ? (
                    <Div100vh>{buildMenu(lang)}</Div100vh>
                ) : (
                    buildMenu(lang)
                )}
            </nav>
        </HeaderWrapper>
    );
};
Menu.propTypes = {
    siteTitle: PropTypes.string,
};
Menu.defaultProps = {
    siteTitle: ``,
};
export default Menu;
