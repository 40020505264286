import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import colors from '../../styles/colors';
import Logo from '../../svg/logo.inline.svg';

const SLIDE_DURATION = '0.8s';
const FADE_OUT_DURATION = '.4s';
const LEAVE_DELAY = '0.4s';

const ratios = {
    square: [1, 1],
    vertical: [1, 2],
    horizontal: [5, 2],
};

const slides = [
    {
        image: {
            name: 'alpha',
            size: 'square',
            position: 'center',
            widths: [220, 532, 720, 960],
        },
        text: {
            position: { x: 100, y: 80 },
            EN: 'Alpha',
            FR: 'Alpha',
        },
    },
    {
        image: {
            name: 'challenge',
            size: 'vertical',
            position: 'left',
            widths: [170, 341, 436, 480],
        },
        text: {
            position: { x: 115, y: 30 },
            EN: 'Challenge',
            FR: 'Challenge',
        },
    },
    {
        image: {
            name: 'commitment',
            size: 'horizontal',
            position: 'center',
            widths: [300, 802, 960],
        },
        text: {
            position: { x: 10, y: 80 },
            EN: 'Commitment',
            FR: 'Engagement',
        },
    },
    {
        image: {
            name: 'family-spirit',
            size: 'square',
            position: 'center',
            widths: [220, 682, 960],
        },
        text: {
            position: { x: 100, y: 50 },
            EN: 'Family Spirit',
            FR: 'Esprit de famille',
        },
    },
    // {
    //     image: {
    //         name: 'network',
    //         size: 'vertical',
    //         position: 'right',
    //         widths: [170, 381, 480],
    //     },
    //     text: {
    //         position: { x: -5, y: 75 },
    //         EN: 'Network',
    //         FR: 'Réseau',
    //     },
    // },
    // {
    //     image: {
    //         name: 'future',
    //         size: 'square',
    //         position: 'center',
    //         widths: [220, 585, 960],
    //     },
    //     text: {
    //         position: { x: 0, y: 20 },
    //         EN: 'Future',
    //         FR: 'Futur',
    //     },
    // },
    {
        image: {
            name: 'risk-management',
            size: 'vertical',
            position: 'left',
            widths: [170, 384, 480],
        },
        text: {
            position: { x: 150, y: 50 },
            EN: (
                <>
                    Risk
                    <br /> Management
                </>
            ),
            FR: (
                <>
                    Gestion
                    <br /> de risque
                </>
            ),
        },
    },
    // {
    //     image: {
    //         name: 'synergy',
    //         size: 'square',
    //         position: 'center',
    //         widths: [220, 960],
    //     },
    //     text: {
    //         position: { x: 10, y: 70 },
    //         EN: 'Synergy',
    //         FR: 'Synergie',
    //     },
    // },
    {
        image: {
            name: 'vision',
            size: 'horizontal',
            position: 'center',
            widths: [300, 844, 960],
        },
        text: {
            position: { x: 95, y: 25 },
            EN: 'Vision',
            FR: 'Vision',
        },
    },
];

const ending = {
    EN: ['The future of ultra high', 'net worth advisor'],
    FR: [`L'avenir du conseil`, 'en ultra-high net worth'],
};

const sizes = {
    square: { mobile: 50, desktop: 30 },
    horizontal: { mobile: 70, desktop: 50 },
    vertical: { mobile: 40, desktop: 20 },
};

const breakpoint = 800;
const mobile = `(max-width: ${breakpoint - 1}px)`;
const desktop = `(min-width: ${breakpoint}px)`;

const Wrapper = styled.div/* scss */ `
    display: flex;
    justify-content: center;
    align-items: center;

    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    font-family: 'Unna';
    z-index: 1000;

    transition: 0s ${FADE_OUT_DURATION} visibility;

    .background {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: ${colors.dark_blue};
        z-index: -1;
        transform-origin: top;
        transition: ${FADE_OUT_DURATION} opacity;
    }

    &:not(.visible) {
        visibility: hidden;

        .background {
            opacity: 0;
        }
    }

    .slide {
        display: flex;
        justify-content: center;
        align-items: center;

        position: absolute;

        @media ${desktop} {
            &.left {
                transform: translateX(-100%);
            }

            &.right {
                transform: translateX(100%);
            }
        }

        &:not(.active) {
            visibility: hidden;
        }
    }

    .slideImgWrapper {
        overflow: hidden;
    }

    .slideImg {
        display: block;
        height: auto;

        @media ${mobile} {
            opacity: 0.7;
        }

        ${Object.entries(sizes).map(([key, val]) => {
            return /* scss */ `
                &.${key} {
                    width: ${val.desktop}vw;
        
                    @media ${mobile} {
                        width: ${val.mobile}vw;
                    }
                }
            `;
        })}
    }

    .slideText {
        position: absolute;
        color: #fff;
        font-size: 6vw;
        letter-spacing: 0.03em;
        white-space: nowrap;
        transform: translate(-50%, -50%);

        @media ${mobile} {
            top: 50% !important;
            left: 50% !important;
            font-size: 10vw;
        }
    }

    .ending {
        text-align: center;

        &:not(.active) {
            visibility: hidden;
        }
    }

    .endingLogo {
        width: 20vw;
        margin-bottom: 1em;

        @media ${mobile} {
            width: 50vw;
        }
    }

    .endingLineWrapper {
        overflow: hidden;
    }

    .endingLine {
        margin: -0.15em 0;
        padding: 0.2em 0;
        font-size: 4.5vw;

        background-image: linear-gradient(to right, ${colors.pink}, #666593);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        @media ${mobile} {
            font-size: 8.5vw;
        }
    }

    .active {
        .slideImgWrapper,
        .slideImg {
            animation: ${SLIDE_DURATION} imgScale ease-out;
        }

        .slideText {
            animation: ${SLIDE_DURATION} textScale ease-out;
        }

        .endingLine {
            will-change: transform;
            animation: 1s lineEnter cubic-bezier(0.3, 0.5, 0.4, 1) backwards;
        }
    }

    &.leaving {
        .endingLine {
            animation: 0.4s lineLeave cubic-bezier(0.4, 0, 0.8, 0.5) forwards;
            animation-delay: ${LEAVE_DELAY} !important;
        }

        .background {
            animation: 1.2s ${LEAVE_DELAY} backgroundLeaving forwards;
        }

        .endingLogo {
            transition-duration: 0.4s;
            transition-delay: ${LEAVE_DELAY};
            transition-property: opacity, transform;
            opacity: 0;
            transform: translateY(-100%);
        }
    }

    @keyframes imgScale {
        0% {
            transform: scale(1.1);
        }
    }

    @keyframes textScale {
        0% {
            opacity: 0;
            transform: translate(-50%, -50%) scale(1.1);
        }
        15% {
            opacity: 0;
        }
    }

    @keyframes lineEnter {
        0% {
            transform: translateY(100%);
        }
    }

    @keyframes lineLeave {
        100% {
            transform: translateY(-100%);
        }
    }

    @keyframes backgroundLeaving {
        100% {
            transform: scaleY(0);
        }
    }
`;

export default function Intro({ onEnded, visible, playing, lang }) {
    const [index, setIndex] = useState(-1);

    const showEnding = index >= slides.length;
    const leave = index > slides.length;

    useEffect(() => {
        if (playing) setIndex(0);
    }, [playing]);

    return (
        <Wrapper
            className={[visible && 'visible', leave && 'leaving']
                .filter(Boolean)
                .join(' ')}
        >
            {/* Background */}
            <div aria-hidden onAnimationEnd={onEnded} className="background" />

            {/* Slides */}
            {slides.map(
                ({ image: { position, size, name, widths }, text }, i) => {
                    const [w, h] = ratios[size];
                    const { x, y } = text.position;
                    const activeClassName = index === i ? 'active' : '';
                    const maxWidth = widths[widths.length - 1];
                    const path = `/images/intro/${name}/${name}`;
                    const { mobile: mobileSize, desktop: desktopSize } = sizes[
                        size
                    ];
                    const imgSizes = `(max-width: ${
                        breakpoint - 1
                    }px) ${mobileSize}vw, ${desktopSize}vw`;
                    const srcSet = widths
                        .map((w) => `${path}@${w}.jpg ${w}w`)
                        .join(',');

                    return (
                        <div
                            key={i}
                            className={`slide ${position} ${activeClassName}`}
                        >
                            {/* Slide img */}
                            <div className={'slideImgWrapper'}>
                                <img
                                    width={w}
                                    height={h}
                                    loading="lazy"
                                    srcSet={srcSet}
                                    sizes={imgSizes}
                                    decoding="async"
                                    className={'slideImg ' + size}
                                    src={`${path}@${maxWidth}.jpg`}
                                    onAnimationEnd={() => setIndex(index + 1)}
                                />
                            </div>

                            {/* Slide text */}
                            <p
                                className="slideText"
                                style={{ left: x + '%', top: y + '%' }}
                            >
                                {text[lang || 'EN']}
                            </p>
                        </div>
                    );
                },
            )}

            {/* Ending */}
            <div className={'ending' + (showEnding ? ' active' : '')}>
                <Logo className="endingLogo" />

                {ending[lang || 'EN'].map((line, i, arr) => (
                    <div key={i} className="endingLineWrapper">
                        <p
                            className="endingLine"
                            style={{ animationDelay: i * 200 + 200 + 'ms' }}
                            onAnimationEnd={() =>
                                i === arr.length - 1 && setIndex(index + 1)
                            }
                        >
                            {line}
                        </p>
                    </div>
                ))}
            </div>
        </Wrapper>
    );
}
