import React, { useEffect } from 'react';

export default function useViewport() {
    const [width, setWidth] = React.useState(undefined);

    useEffect(() => {
        function changeWidth() {
            setWidth(window.innerWidth);
        }

        window.addEventListener('resize', changeWidth);

        changeWidth();

        return () => {
            window.removeEventListener('resize', changeWidth);
        };
    }, []);

    return { width };
}
