import { graphql, useStaticQuery } from 'gatsby';
import React, { useEffect, useRef, useState } from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';
import styled from 'styled-components';
import Intro from '../components/common/Intro';
import colors from '../styles/colors.js';
import '../styles/global.css';
import grid from '../styles/grid.js';
import useViewport from '../utils/useViewport';
import Constellations from './common/Constallations/Constellations';
import Footer from './common/Footer';
import Menu from './common/Menu';

const Wrapper = styled.div`
    transition: all 500ms ease-in-out;
    color: white;
    background: ${colors.dark_blue};
    *,
    *::before,
    *::after {
        box-sizing: border-box;
        outline: none;
    }
    .mask {
        position: absolute;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        background: black;
    }
    .title-label {
        color: ${colors.pink};
    }
    .title-4 {
        color: ${colors.pink};
    }
    @media screen and (min-width: 800px) {
        padding-bottom: 0;
    }
`;

const ContentWrapper = styled.div`
    transition: all 500ms ease-in-out;
    display: grid;
    grid-template-columns: ${grid.templateMobile};
    .footer {
        grid-column: 1/5;
    }
    .constel {
        display: none;
    }
    @media screen and (min-width: 800px) {
        transition: all 0ms;
        grid-template-columns: ${grid.templateDesktop};
        position: relative;
        padding-top: 150px;
        .footer {
            grid-column: 1/15;
        }
    }
`;

const Layout = ({ children, className, lang, pathTranslatePage }) => {
    let data = useStaticQuery(graphql`
        query {
            allWpMenu {
                nodes {
                    menuItems {
                        nodes {
                            path
                            label
                        }
                    }
                    locations
                }
            }
            wp {
                sp {
                    options {
                        theme {
                            acf {
                                linkedin
                                logo_ac {
                                    title
                                    url
                                }
                            }
                        }
                    }
                }
            }
        }
    `);
    let dataMenu;
    let dataFooter;

    for (let i = 0; i < data.allWpMenu.nodes.length; i++) {
        if (
            lang === 'FR' &&
            data.allWpMenu.nodes[i].locations[0] === 'MAIN___FR'
        ) {
            dataMenu = data.allWpMenu.nodes[i].menuItems.nodes;
        } else if (
            lang === 'FR' &&
            data.allWpMenu.nodes[i].locations[0] === 'FOOTER___FR'
        ) {
            dataFooter = data.allWpMenu.nodes[i].menuItems.nodes;
        } else if (data.allWpMenu.nodes[i].locations[0] === 'MAIN') {
            dataMenu = data.allWpMenu.nodes[i].menuItems.nodes;
        } else if (data.allWpMenu.nodes[i].locations[0] === 'FOOTER') {
            dataFooter = data.allWpMenu.nodes[i].menuItems.nodes;
        }
    }

    dataMenu.forEach((item) => {
        switch (item.path) {
            case '/fr/gestion-du-patrimoine/':
                item.anchors = [
                    {
                        title: 'Gestion du patrimoine',
                        tag: 'wealth-management',
                    },
                    {
                        title: 'Divertissement sportif',
                        tag: 'sport-entertainment',
                    },
                ];
                break;
            case '/wealth-management/':
                item.anchors = [
                    {
                        title: 'Wealth Management',
                        tag: 'wealth-management',
                    },
                    {
                        title: 'Sport & entertainment',
                        tag: 'sport-entertainment',
                    },
                ];
                break;
            case '/fr/gestion-des-actifs/':
                item.anchors = [
                    {
                        title: "Allocation d'actifs",
                        tag: 'asset-allocation',
                    },
                    {
                        title: 'Stratégies « in-house »',
                        tag: 'in-house-strategies',
                    },
                    {
                        title: 'Dette privée',
                        tag: 'private-debt',
                    },
                    {
                        title: 'Investissements directs',
                        tag: 'direct-investments',
                    },
                    {
                        title: "Fonds d'arbitrage <br>de crypotomonnaies",
                        tag: 'cryptocurrency-arbitrage-fund',
                    },
                ];
                break;
            case '/asset-management/':
                item.anchors = [
                    {
                        title: 'Asset Allocation',
                        tag: 'asset-allocation',
                    },
                    {
                        title: 'In House strategies',
                        tag: 'in-house-strategies',
                    },
                    {
                        title: 'Private debt',
                        tag: 'private-debt',
                    },
                    {
                        title: 'Direct investments',
                        tag: 'direct-investments',
                    },
                    {
                        title: 'Cryptocurrency <br> arbitrage fund',
                        tag: 'cryptocurrency-arbitrage-fund',
                    },
                ];
                break;
            default:
                break;
        }
    });
    const { width } = useViewport();
    const breakpoint = 800;
    const wrapperEl = useRef(null);

    const [introVisible, setIntroVisible] = useState(true);
    const [popinCookiesShown, setPopinCookiesShown] = useState(true);
    const [introPlaying, setIntroPlaying] = useState(false);

    useEffect(() => {
        if (sessionStorage.getItem('skipIntro')) setIntroVisible(false);
        else setIntroPlaying(true);

        // On détecte quand le module quantcast n'est pas affiché
        if ('__tcfapi' in window) {
            window.__tcfapi('addEventListener', 2, (e) => {
                if (e.eventStatus !== 'cmpuishown') setPopinCookiesShown(false);
            });
        } else {
            setPopinCookiesShown(false);
        }
    }, []);

    function openMenu() {
        wrapperEl.current.style.transform = 'translate(-83%)';
        document.body.style.overflow = 'hidden';
    }

    function closeMenu() {
        wrapperEl.current.style.transform = 'translate(0)';
        document.body.style.overflow = 'unset';
    }

    function offsetWrapper(value) {
        wrapperEl.current.style.paddingTop = value + 20 + 'px';
    }

    return (
        <ParallaxProvider>
            <Wrapper className={className}>
                <Intro
                    lang={lang}
                    visible={introVisible}
                    playing={introPlaying && !popinCookiesShown}
                    onEnded={() => {
                        sessionStorage.setItem('skipIntro', 'yup');
                        setIntroVisible(false);
                    }}
                />

                <Menu
                    menuLinks={dataMenu}
                    lang={lang}
                    openMenuLayout={openMenu}
                    closeMenuLayout={closeMenu}
                    logo={data.wp.sp.options.theme.acf.logo_ac}
                    contentOffset={offsetWrapper}
                />

                <ContentWrapper ref={wrapperEl} className="contentWrapper">
                    {children}

                    {width > breakpoint && (
                        <Constellations className="constellations" />
                    )}

                    <Footer
                        lang={lang}
                        footerLinks={dataFooter}
                        className="footer"
                        pathTranslatePage={pathTranslatePage}
                        urlLinkedin={data.wp.sp.options.theme.acf.linkedin}
                    />
                </ContentWrapper>
            </Wrapper>
        </ParallaxProvider>
    );
};

export default Layout;
