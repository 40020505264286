import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import colors from '../../styles/colors';
import grid from '../../styles/grid';
import LinkedinLogo from '../../svg/linkedin.inline.svg';

const Wrapper = styled.div`
    position: relative;
    width: 100%;
    background: ${colors.dark_blue};
    grid-column: 1/3;
    display: grid;
    grid-template-columns: ${grid.templateMobile};
    transition: transform 500ms ease-in-out;
    .innerContent {
        grid-column: 2/3;
        height: 70px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        p {
            font-family: 'Heebo';
            font-weight: 300;
            font-size: 1.2rem;
        }
        .rightSide {
            display: flex;
            align-items: flex-end;
            .container-lang {
                display: none;
            }
            .legals {
                font-family: 'Heebo';
                font-weight: 300;
                text-transform: uppercase;
                font-size: 1.2rem;
                letter-spacing: 0.3rem;
                margin: 0 20px;
            }
        }
    }
    @media screen and (min-width: 800px) {
        grid-column: 1/15;
        display: grid;
        grid-template-columns: ${grid.templateDesktop};
        .innerContent {
            height: 80px;
            grid-column: 3/13;
            p {
                font-size: 1.4rem;
            }
            .rightSide {
                .container-lang {
                    font-family: 'Heebo';
                    font-weight: 300;
                    text-transform: uppercase;
                    font-size: 1.2rem;
                    letter-spacing: 0.3rem;
                    display: block;
                    &.EN {
                        .en {
                            font-weight: bold;
                            pointer-events: none;
                        }
                    }
                    &.FR {
                        .fr {
                            font-weight: bold;
                            pointer-events: none;
                        }
                    }
                    .en {
                        margin-right: 5px;
                    }
                    .fr {
                        margin-left: 5px;
                    }
                }
            }
        }
    }
`;

const Footer = (
    { className, lang, footerLinks, pathTranslatePage, urlLinkedin },
    ref,
) => (
    <Wrapper ref={ref} className={className}>
        <div className="innerContent">
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.sweetpunk.com"
            >
                <p>Powered by Sweet Punk</p>
            </a>
            <div className="rightSide">
                <a href={urlLinkedin}>
                    <LinkedinLogo />
                </a>
                {footerLinks.map((link) => (
                    <Link to={link.path} key={link.label} className="legals">
                        {link.label}
                    </Link>
                ))}
                <div className={lang + ' container-lang'}>
                    <Link
                        to={lang === 'FR' ? pathTranslatePage : '/'}
                        className="en"
                    >
                        EN
                    </Link>
                    |
                    <Link
                        to={lang === 'EN' ? pathTranslatePage : '/fr'}
                        className="fr"
                    >
                        FR
                    </Link>
                </div>
            </div>
        </div>
    </Wrapper>
);

export default React.forwardRef(Footer);
